<template>
    <div v-loading.fullscreen.lock="fullscreenLoading">
        <Navigation />
        <!-- 一点成诗 -->
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>
                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”-AI写诗" />
                        <ai_select selectTitle="风格类型" :select_list="typeList" @getSelectVal="get_type" />
                        <ai_select selectTitle="诗体类型" :select_list="fontTypetList" @getSelectVal="get_font" />
                        <div class="form_ct flex">
                            <p class="upLoad_text">主题描述：</p>
                            <div class="upload_box">
                                <el-input maxlength="50" v-model="form.business"
                                    placeholder="如：风、雨、花朵、事物、人物等"></el-input>
                            </div>
                        </div>
                        <ai_button_file_data @file_data="file_data" />
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_title from '@/components/ai_title.vue'
import ai_text_content from '@/components/ai_text_content'
import ai_select from '@/components/ai_select.vue'
import ai_button_file_data from '@/components/ai_button_file_data.vue'
export default {
    name: '',
    created() { },
    mounted() {
        this.getUserInfo()
    },
    components: {
        wordNav,
        ai_title,
        ai_select,
        ai_text_content,
        ai_button_file_data
    },
    data() {
        return {
            name: '企业管理',
            form: {
                type: '',
                business: '',
                fontType: '',
            },
            typeList: [
                {
                    id: '豪迈雄奇',
                    name: '豪迈雄奇'
                },
                {
                    id: '悲壮慷慨',
                    name: '悲壮慷慨'
                },
                {
                    id: '生动形象',
                    name: '生动形象'
                },
                {
                    id: '绚丽飘逸',
                    name: '绚丽飘逸'
                },
                {
                    id: '沉郁顿挫',
                    name: '沉郁顿挫'
                },
                {
                    id: '幽默讽刺',
                    name: '幽默讽刺'
                },
                {
                    id: '朴素自然',
                    name: '朴素自然'
                },
                {
                    id: '清新明丽',
                    name: '清新明丽'
                },
                {
                    id: '通俗明快',
                    name: '通俗明快'
                },
                {
                    id: '婉约细腻',
                    name: '婉约细腻'
                },
                {
                    id: '含蓄委婉',
                    name: '含蓄委婉'
                },
            ],
            fontTypetList: [
                {
                    id: '楚辞体',
                    name: '楚辞体',
                },
                {
                    id: '歌行体',
                    name: '歌行体',
                },
                {
                    id: '乐府诗',
                    name: '乐府诗',
                },
                {
                    id: '七言绝句',
                    name: '七言绝句',
                },
                {
                    id: '五言律诗',
                    name: '五言律诗',
                },
                {
                    id: '叙事诗',
                    name: '叙事诗',
                },
                {
                    id: '抒情诗',
                    name: '抒情诗',
                },
                {
                    id: '格律诗',
                    name: '格律诗',
                },
                {
                    id: '自由诗',
                    name: '自由诗',
                },
                {
                    id: '散文诗',
                    name: '散文诗',
                },
                {
                    id: '藏头诗',
                    name: '藏头诗',
                },

            ],
            fullscreenLoading: false,
            contentData: {}, //内容
            aiNumShow: false,
            aiPoupShow: false,
        }
    },
    methods: {
        get_type(val) {
            this.form.type = val
        },
        get_font(val) {
            this.form.fontType = val
        },
        getUserInfo() {
            let $user_info = JSON.parse(localStorage.getItem('user_info'))
            if ($user_info) {
                this.curlGet('/api/user/info').then((res) => {
                    if (res.data.code) {
                        this.$user_info = res.data.data
                        this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                    }
                })
            }
        },
        file_data() {
            // if (this.form.type == '') {
            //     return this.$message({
            //         message: '请输入从事行业',
            //         type: 'warning',
            //         offset: 80
            //     });
            // }
            // if (this.form.business == '') {
            //     return this.$message({
            //         message: '请输入主营业务',
            //         type: 'warning',
            //         offset: 80
            //     });
            // }
            // if (this.form.kesWord == '') {
            //     return this.$message({
            //         message: '请输入关键字',
            //         type: 'warning',
            //         offset: 80
            //     });
            // }
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true

                // this.postData()
            }
        },
        postData() {
            this.aiNumShow = false
            this.fullscreenLoading = true
            this.curlPost('/file/poetry_create', {
                type: this.form.type,
                business: this.form.business,
                fontType: this.form.fontType,
                mobile: this.$user_info.mobile,

            }).then(res => {
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()

                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
                this.fullscreenLoading = false
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>